<div class="row box-conteudo box-card pb-4">
    <div class="col-12" role="autor">
        <div class="row">
            <div class="col-12 item-title">
                <span class="subtitulo sub-title-jl ">NOSSOS PLANOS</span>
                <i class="fa fa-question-circle-o icon-help" title="Informações sobre" role="button"
                    aria-label="Informações sobre personagens, para ativar use a tecla ENTER">
                </i>
                <hr>
                <h6>
                    Aqui você tem muitos beneficios: <br>
                </h6>
                <ul>
                    <li>Não exigimos que compre a venda casada, sendo obrigado assinar outros serviços para ter uma internet de alta velocidade</li>
                    <li>Valores de planos transparentes que não aumentam após alguns meses.</li>
                    <li>Sem Consulta SPC ou SERASA!</li>
                    <li>Plano mensalidade Pós-Pago.</li>
                  </ul>                
                <br>
                <strong>Planos residências referentes a pessoa física, para pessoa Juridica por gentileza entre em contato conosco.</strong>
    

                <br><br>
                    
            </div>
        </div>

        <div>
            <div class="row">
                <div class="row col-12">                    
                    <div class="col-lg-3" >
                        <p-card>
                            <div class="ptp-plan" style="height: 25px;">80 MEGAS <i class="fas fa-tachometer-alt"></i>
                            </div>
                            <div class="ptp-price" style="height: 94.5px;"><label class="money">R$</label><label class="valor-plan">80</label><label class="cents">,00</label></div>
                            <div class="ptp-bullet-item ptp-row-id-0" style="height: 21.75px;">
                                Até 80Mbps Download e Upload
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-1" style="height: 21.75px;">
                              Sem Franquia
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-2" style="height: 21.75px;">
                                Disponibilidade 24/7 
                            </div>
                            <div class="ptp-cta" style="height: 49px;">
                                <a class="ptp-button" id="ptp-7401-cta-0" href="#contato" style="height: 20px;">ASSINE
                                    JÁ!</a>
                            </div>
                        </p-card>
                    </div>
                    <div class="col-lg-3">
                        <p-card>
                            <div class="ptp-plan" style="height: 25px;">100 MEGAS <i class="fas fa-tachometer-alt"></i>
                            </div>
                            <div class="ptp-price" style="height: 94.5px;"><label class="money">R$</label><label class="valor-plan">100</label><label class="cents">,00</label></div>
                            <div class="ptp-bullet-item ptp-row-id-0" style="height: 21.75px;">
                                Até 100Mbps Download e Upload
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-1" style="height: 21.75px;">
                              Sem Franquia
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-2" style="height: 21.75px;">
                                Disponibilidade 24/7 
                            </div>
                            <div class="ptp-cta" style="height: 49px;">
                                <a class="ptp-button" id="ptp-7401-cta-0" href="#contato" style="height: 20px;">ASSINE
                                    JÁ!</a>
                            </div>
                        </p-card>
                    </div>
                    <div class="col-lg-3">
                        <p-card class="ptp-item-container">
                            <div class="ptp-plan" style="height: 25px;">150 MEGAS <i class="fas fa-tachometer-alt"></i>
                            </div>
                            <div class="ptp-price" style="height: 94.5px;"><label class="money">R$</label><label class="valor-plan">110</label><label class="cents">,00</label></div>
                            <div class="ptp-bullet-item ptp-row-id-0" style="height: 21.75px;">
                                Até 150Mbps Download e Upload
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-1" style="height: 21.75px;">
                              Sem Franquia
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-2" style="height: 21.75px;">
                                Disponibilidade 24/7 
                            </div>
                            <div class="ptp-cta" style="height: 49px;">
                                <a class="ptp-button" id="ptp-7401-cta-0" href="#contato" style="height: 20px;">ASSINE
                                    JÁ!</a>
                            </div>
                        </p-card>
                    </div>
                    <div class="col-lg-3">
                        <p-card>
                            <div class="ptp-plan" style="height: 25px;">250 MEGAS <i class="fas fa-tachometer-alt"></i>
                            </div>
                            <div class="ptp-price" style="height: 94.5px;"><label class="money">R$</label><label class="valor-plan">130</label><label class="cents">,00</label></div>
                            <div class="ptp-bullet-item ptp-row-id-0" style="height: 21.75px;">
                                Até 250Mbps Download e Upload
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-1" style="height: 21.75px;">
                              Sem Franquia
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-2" style="height: 21.75px;">
                                Disponibilidade 24/7 
                            </div>
                            <div class="ptp-cta" style="height: 49px;">
                                <a class="ptp-button" id="ptp-7401-cta-0" href="#contato" style="height: 20px;">ASSINE
                                    JÁ!</a>
                            </div>
                        </p-card>
                    </div>                 
                </div>
            </div>

            
            <br>
            <div class="row">
                <div class="row col-12 justify-content-center">  
                    <div class="col-lg-3">
                        <p-card>
                            <div class="ptp-plan" style="height: 25px;">350 MEGAS <i class="fas fa-tachometer-alt"></i>
                            </div>
                            <div class="ptp-price" style="height: 94.5px;"><label class="money">R$</label><label class="valor-plan">150</label><label class="cents">,00</label></div>
                            <div class="ptp-bullet-item ptp-row-id-0" style="height: 21.75px;">
                                Até 350Mbps Download e Upload
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-1" style="height: 21.75px;">
                              Sem Franquia
                            </div>
                            <div class="ptp-bullet-item ptp-row-id-2" style="height: 21.75px;">
                                Disponibilidade 24/7 
                            </div>
                            <div class="ptp-cta" style="height: 49px;">
                                <a class="ptp-button" id="ptp-7401-cta-0" href="#contato" style="height: 20px;">ASSINE
                                    JÁ!</a>
                            </div>
                        </p-card>
                    </div>

                    <div class="col-lg-3 ">
                        <p-card>
             </div>

                 </div>
            </div>



        </div>


    </div>

    <div class="col-12" role="autor">
        <div class="row">
        </div>
    </div>
</div>