<div class="row box-conteudo box-card pb-4">
    <div class="col-12" role="autor">
        <div class="row">
            <div class="col-12 item-title">
                <span class="subtitulo sub-title-jl ">CONTATO</span>
                <i class="fa fa-question-circle-o icon-help" title="Informações sobre" role="button"
                    aria-label="Informações sobre personagens, para ativar use a tecla ENTER">
                </i>
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="row col-12">
                <div class="col-5">
                    <p class="contato">Aŕea dedicada para tirar as suas dúvidas e responder a maioria das questões sobre o nossa empresa. Caso queira uma outra informação, tirar uma dúvidas específica ou fazer uma reclamação, não hesite em nos contactar. Teremos um maior prazer em atendê-lo para solucionar a sua solicitação. Você pode usar o telefone abaixo, mandar um email direto para nossa equipe ou se preferir usar o formulário abaixo com os campos devidamente preenchidos para que possamos retornar para você.</p>
                    <br>
                    <p><b>Telefone:</b> 21 3890 1846 </p>
                    <p>Segunda à Sexta das 09:00 às 20:00hrs.</p>
                    <p>Sábado de 10:00 às 19:00hrs | Domingo e Feriados 10:00 às 16:00hrs</p>
                    <p>comercial@jltelecom.net</p>
                </div>
                <div class="col-1">
                </div>

                <div class="col-6">
                                    </div>
            </div>
        </div>
    </div>
</div>